<script setup lang="ts">
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import { MODALS } from "~/stores/modals";
import VIosPwaInstruction from "~/components/VIosPwaInstruction.vue";

const modalsStore = useModalsStore();
const { send } = useAnalytics();

const isActive = computed(() => modalsStore.getActive() === MODALS.PwaIosInstruction);

const isOpen = computed(() => modalsStore.isOpen(MODALS.PwaIosInstruction));

const onClose = () => {
  send("App:Ios:Instruction:Popup:Close:Click");
  modalsStore.close(MODALS.PwaIosInstruction);
};

watch(isOpen, (next) => {
  if (next) {
    send("App:Ios:Instruction:Popup:View");
  }
});
</script>

<template>
  <VModal
    :id="MODALS.PwaIosInstruction"
    :active="isActive"
    :open="isOpen"
    :closable="true"
    type="bottom-sheet"
    title="Добавьте иконку для входа"
    wrapper-class="modal-ios-instruction_main"
    @close="onClose"
  >
    <VIosPwaInstruction description-text="Магнит Онлайн будет на рабочем столе" />
  </VModal>
</template>

<style lang="postcss">
.modal-ios-instruction_main {
  & .pl-modal-header {
    &__control-wrapper {
      min-width: var(--pl-unit-x6);
    }
  }
}
</style>
