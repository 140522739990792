<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import VTabBar from "@magnit/core/src/components/VTabBar/VTabBar.vue";
import ShopIcon from "@magnit/icons/src/assets/icons/base-24-outline-shop.svg";
import CardIcon from "@magnit/icons/src/assets/icons/base-24-outline-card-magnit.svg";
import HumanIcon from "@magnit/icons/src/assets/icons/base-24-outline-human.svg";
import type {
  ITabBarItem,
  ITabBarPayload,
} from "@magnit/core/src/components/VTabBar/VTabBar.types";
import { Routes } from "~/utils/routes";

enum NavName {
  Main = "Main",
  QrCode = "QrCode",
  Profile = "Profile",
}

const analyticEventsMap: Record<string, string> = {
  Main: "Main",
  QrCode: "Card",
  Profile: "My",
};

const navItems: ITabBarItem[] = [
  {
    name: NavName.Main,
    icon: ShopIcon,
    text: "Сегодня",
    link: Routes.Main,
  },
  {
    name: NavName.QrCode,
    icon: CardIcon,
    text: "Карта",
    link: Routes.QrCode,
  },
  {
    name: NavName.Profile,
    icon: HumanIcon,
    text: "Профиль",
    link: Routes.Profile,
  },
];

const router = useRouter();
const route = useRoute();
const { send } = useAnalytics();

const active = computed<NavName | null>(() => {
  if (route.path.startsWith(Routes.Profile)) {
    return NavName.Profile;
  }
  if (route.path === Routes.QrCode) {
    return NavName.QrCode;
  }
  if (route.path === Routes.Main) {
    return NavName.Main;
  }
  return null;
});

const onClick = (payload: ITabBarPayload) => {
  send(`Navbar:${analyticEventsMap[payload.name]}:Click`);
  router.push(payload.link);
};

onMounted(() => {
  send("Navbar:View");
});
</script>

<template>
  <VTabBar :items="navItems" :active="active" @on-click="onClick($event)" />
</template>
