<script setup lang="ts">
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import { MODALS } from "~/stores/modals";

const modalsStore = useModalsStore();
const platformStore = usePlatformStore();
const pwaStore = usePwaStore();
const { send } = useAnalytics();

const isActive = computed(() => {
  return modalsStore.getActive() === MODALS.InstallSuccess;
});

const isOpen = computed(() => {
  return modalsStore.isOpen(MODALS.InstallSuccess);
});

const onClose = () => {
  modalsStore.close(MODALS.InstallSuccess);
  if (platformStore.platformInfo.pwa && typeof window !== "undefined") {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

watch(isOpen, (next) => {
  if (next) {
    send("App:Install:Banner:View");
  }
});
</script>

<template>
  <VModal
    :id="MODALS.InstallSuccess"
    :active="isActive"
    :open="isOpen"
    :type="{ xs: 'bottom-sheet', m: 'modal' }"
    title="Готово!"
    @close="onClose"
  >
    <div class="install-complete-modal">
      <VText class="install-complete-modal__text">
        Магнит Онлайн уже ждет вас на главном&nbsp;экране
      </VText>
      <img src="~/assets/images/pwa-ready.png" alt="app-ready">
      <VButton theme="primary" full-width @click="onClose">
        Хорошо
      </VButton>
    </div>
  </VModal>
</template>

<style lang="postcss">
.install-complete-modal {
  text-align: center;

  &__text {
    display: inline-block;
    margin: 0 0 var(--pl-unit-x4);
  }

  & img {
    display: block;
    max-width: 100%;
    margin-bottom: var(--pl-unit-x5);
  }
}
</style>
