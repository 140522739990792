import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import type { Messaging } from "firebase/messaging";
import { fbaseConfig } from "~/stores/push";

const useFirebaseInit = () => {
  const messaging = ref<Messaging | null>(null);
  onMounted(async () => {
    const app = initializeApp(fbaseConfig);
    const permissionStatus = await navigator.permissions.query({
      name: "notifications",
    });
    if (permissionStatus.state === "granted") {
      messaging.value = getMessaging(app);
      onMessage(messaging.value, async (payload) => {
        const payloadData = payload?.data;
        if (navigator && payload) {
          const registration = await navigator.serviceWorker.getRegistration();
          navigator.serviceWorker?.controller?.postMessage({
            ...payloadData,
            type: "front-push-received",
          });
          if (registration) {
            await registration.showNotification(payload.notification?.title || "", {
              ...payload.notification,
              data: {
                ...payload.data,
                FCM_MSG: { data: { ...payload.data } },
              },
            });
          }
        }
      });
    }
  });
};

export default useFirebaseInit;
